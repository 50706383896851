// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alto-digital-js": () => import("./../../../src/pages/alto-digital.js" /* webpackChunkName: "component---src-pages-alto-digital-js" */),
  "component---src-pages-barauna-digital-js": () => import("./../../../src/pages/barauna-digital.js" /* webpackChunkName: "component---src-pages-barauna-digital-js" */),
  "component---src-pages-caraubas-conectada-js": () => import("./../../../src/pages/caraubas-conectada.js" /* webpackChunkName: "component---src-pages-caraubas-conectada-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-lagoa-nova-digital-js": () => import("./../../../src/pages/lagoa-nova-digital.js" /* webpackChunkName: "component---src-pages-lagoa-nova-digital-js" */),
  "component---src-pages-mobby-js": () => import("./../../../src/pages/mobby.js" /* webpackChunkName: "component---src-pages-mobby-js" */),
  "component---src-pages-mossoro-conectada-js": () => import("./../../../src/pages/mossoro-conectada.js" /* webpackChunkName: "component---src-pages-mossoro-conectada-js" */),
  "component---src-pages-natal-digital-js": () => import("./../../../src/pages/natal-digital.js" /* webpackChunkName: "component---src-pages-natal-digital-js" */),
  "component---src-pages-pendencias-digital-js": () => import("./../../../src/pages/pendencias-digital.js" /* webpackChunkName: "component---src-pages-pendencias-digital-js" */),
  "component---src-pages-torrecera-conecta-js": () => import("./../../../src/pages/torrecera-conecta.js" /* webpackChunkName: "component---src-pages-torrecera-conecta-js" */)
}

